@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Black.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Bold.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-SemiBold.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Medium.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Regular.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-weight: 400;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Light.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BR Firma";
  src:
    url("./fonts/br-firma/BR-Firma-Thin.otf") format("opentype"),
    url("./fonts/br-firma/BR-Firma-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
